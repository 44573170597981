import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../images/linkedin-icon.svg";
import { LogoLink } from "../headers/light.js";

import logo2 from "../../images/baobab/icon.png";


const Container = tw.div`relative bg-gray-200 -mx-8 -mb-10 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-8 lg:py-4 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-0 text-sm font-medium`;
const LinkListItem = tw.li`mt-1`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300 hidden lg:block `;

const SocialLinksContainer = tw.div`mt-0 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;



export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <SocialLinksContainer>
            <SocialLink href="https://www.facebook.com/baobab.deals"  rel='nofollow' target="_blank">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://www.linkedin.com/company/baobab-app/"  rel='nofollow' target="_blank">
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
        <LinkList>
            <LinkListItem>
              <Link href="/terms">baobab.deals T&C</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
        <LinkList>
            <LinkListItem>
              <Link href="/terms-business">baobab.business T&C</Link>
            </LinkListItem>
          </LinkList>
        </Column>

        <Column>
          <LinkList>
            <LinkListItem>
              <Link href="/privacy">Privacy</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};
