import React from "react";
import AnimationRevealPageNoPadding from "helpers/AnimationRevealPageNoPadding.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

import Header, { LogoLink, NavToggle} from "./components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import logo2 from "./images/baobab/logo2.png";


const StyledHeader = styled(Header)`
  ${tw`pt-0 pb-0 -mb-2 lg:mb-0 -ml-2 lg:ml-0 lg:pt-4 lg:pb-2 max-w-none w-full`}
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const HeroContainer = tw.div`z-10 relative px-6 sm:px-8 mx-auto h-full flex flex-col border-b-2`;

const HeadingRow = tw.div`flex mx-4`;
const Heading = tw(SectionHeading)`text-gray-900 mb-5`;
const Text = styled.div`
  ${tw`  text-gray-800 mx-4`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-xl font-bold mt-10 mb-5`}
  }
  h2 {
    ${tw`text-lg font-bold mt-8 mb-4`} 
  }
  h3 {
    ${tw`text-lg font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
const logoLink = (
  <LogoLink href="/" >
    <img src={logo2} alt="logo" style={{ height: "40px", width: "150px" }}/>
  </LogoLink>
);
export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPageNoPadding disabled={false}>
      <HeroContainer>
        <StyledHeader links={[]} logoLink={logoLink} />
      </HeroContainer>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading><small>Application Privacy Policy</small></Heading>
          </HeadingRow>
          <HeadingRow>
            <Heading><small><small>For the mobile applications BAOBAB.DEALS and BAOBAB.BUSINESS</small></small></Heading>
          </HeadingRow>
                
          <Text>

          <p>Last updated: December 7, 2021</p>

            <h1>What are the Baobab Apps?</h1>
            <p>
                Baobab Apps are part of Baobab Deals app (https://baobab.deals) and Baobab Business app, platform which connects users and food merchants, giving the opportunity collect food that would otherwise become wasted surplus.
            </p>
            <p>
                Baobab App runs a website located at www.baobab.deals and the Application through which users are given the opportunity to order and collect food from merchants that would otherwise become wasted surplus. 
            </p>
            <p>
                The aim of Baobab App is to use technology to help mitigate the effects of climate change by connecting people who want food with people who are selling it. When it matters and before that food is lost.
            </p>

            <h1>1. We respect your privacy</h1>
            <ul>
              <li>
                BEHOBE TECH LIMITED respects your right to privacy and is committed to safeguarding the privacy of our customers and software application users. We adhere to the National Privacy Principles established by the Privacy Act 1988 (Cth). This policy sets out how we collect and treat your personal information.
              </li>
              <li>
                Personal information is information we hold which is identifiable as being about you.
              </li>
            </ul>

            <h1>2. Collection of personal information</h1>

            <h2>2.1 Registration data</h2>
            <p>
                In order to utilize the services of the Baobab App we will need to retrieve the following information to use the application. This information includes your phone number, recovery email address and a name for your user profile/business.
            </p>
            <h2>2.2 Technical Information</h2>
            <ul>
              <li>
                While using the Baobab Application we retrieve technical information from our users. Users’ information such as IP address, geographic location based on IP address, which website webpages are viewed, browser and/or mobile platform types. Other basic technical information is also retrieved such as the different log in/out times, app activation/deactivation and dates for the websites and applications. This also includes the swipes, clicks and various interactions within the application that are also retrieved and tracked. This information is automatically recorded on our server logs directly from your browser or mobile application.
              </li>
              <li>
                Certain device data may also be retrieved such as your hardware model, operating system current version, language settings and other information from the network of your mobile services provider.   
              </li>
              <li>
                While using the Baobab Application we retrieve technical information from our users. Users’ information such as IP address, geographic location based on IP address, which website webpages are viewed, browser and/or mobile platform types. Other basic technical information is also retrieved such as the different log in/out times, app activation/deactivation and dates for the websites and applications. This also includes the swipes, clicks and various interactions within the application that are also retrieved and tracked. This information is automatically recorded on our server logs directly from your browser or mobile application.
              </li>
              <li>
                  Anonymized identification user data is tracked using web logs. These help us to track the interactions that are performed on the application by the anonymous user. These web logs are stored on cloud and do not place anything on a user’s computer.  
              </li>
            </ul>

            <h2>2.3 Data through use of the applications</h2>
            <ul>
              <li>
                Push notifications are utilized if opted into when onboarding the app in the early sign-up screens. By choosing to allow Baobab App the ability to send you push notifications we will be able to send you the most current notifications about when deals will become available to you. Tokens for these types of notifications are stored and associated with your device. 
              </li>
              <li>
                Location services allow us to notify you of the deals within your specified range of choice. This stops us from showing you meals that are not wanted on your application. We may collect and process information about your actual location (by, example; GPS.). This is all used to provide personalized services to users and fulfil the services provided by the app.
              </li>
              <li>
                Camera access and Photo libraries are used by the Baobab Business application in order to post new deals and create a business profile only. This data is made available by the business app user through personal selection and does not get shared with any outside parties. The only way this data is displayed is as selected by the app user.
              </li>
              <li>
                BEHOBE TECH LIMITED will, from time to time, receive and store personal information you enter onto our software application Baobab App, provide to us directly or give to us in other forms.
              </li>
              <li>
                You may provide basic information such as your name, phone number, address and email address to enable us to send information, provide updates and process your product or service order. We may retrieve additional information at other times, including but not limited to, when you provide feedback, when you provide information about your personal or business affairs, change your content or email preference, respond to surveys and/or promotions, or communicate with our customer support.
              </li>
              <li>
                Additionally, we may also retrieve any other information you provide while interacting with our services.
              </li>
            </ul>

            <h1>3. How we retrieve your personal information</h1>
            <ul>
              <li>
                BEHOBE TECH LIMITED retrieves personal information from you in a variety of ways, including when you interact with us electronically or in person, when you access our software application and when we provide our services to you. We may also receive personal information from third parties. If we do, we will protect it as set out in this Privacy Policy.
              </li>
            </ul>
            
            <h1>4. Use of your personal information</h1>
            <ul>
              <li>
              BEHOBE TECH LIMITED may use personal information retrieved from you to provide you with information, updates and our services. We may also make you aware of new and additional products, services and opportunities available to you. We may use your personal information to improve our products and services and better understand your needs.
              </li>
              <li>
              The Application may make third party social media features available to its users. We cannot ensure the security of any information you choose to make public in a social media feature. Also, we cannot ensure that parties who have access to such publicly available information will respect your privacy. BEHOBE TECH LIMITED may contact you by a variety of measures including, but not limited to telephone, email, SMS or mail.
              </li>
            </ul>
    
            <h1>5. Disclosure of your personal information</h1>
            <ul>
              <li>
              We may disclose your personal information to any of our employees, officers, insurers, professional advisers, agents, suppliers or subcontractors insofar as reasonably necessary for the purposes set out in this Policy. Personal information is only supplied to a third party when it is required for the delivery of our services.  
              </li>
              <li>
              We may from time to time need to disclose personal information to comply with a legal requirement, such as a law, regulation, court order, subpoena, warrant, during a legal proceeding or in response to a law enforcement agency request.
              </li>
              <li>
              We may also use your personal information to protect the copyright, trademarks, legal rights, property or safety of BEHOBE TECH LIMITED, its application, website and customers or third parties.
              </li>
              <li>
              If there is a change of control in our business or a sale or transfer of business assets, we reserve the right to transfer to the extent permissible at law our user databases, together with any personal information and non-personal information contained in those databases. This information may be disclosed to a potential purchaser under an agreement to maintain confidentiality. We would seek to only disclose information in good faith and where required by any of the above circumstances.
              </li>
              <li>
              By providing us with personal information, you consent to the terms of this Privacy Policy and the types of disclosure covered by this Policy. Where we disclose your personal information to third parties, we will request that the third party follow this Policy regarding handling your personal information.
              </li>
            </ul>

            <h1>6. Security of your personal information</h1>
            <ul>
              <li>
              BEHOBE TECH LIMITED is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorized access, modification and disclosure.
              </li>
              <li>
              The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us or receive from us. Although we take measures to safeguard against unauthorized disclosures of information, we cannot assure you that personal information that we retrieve will not be disclosed in a manner that is inconsistent with this Privacy Policy.
              </li>
            </ul>

            <h1>7. Access to your personal information</h1>
            <ul>
              <li>
              You may request details of personal information that we hold about you in accordance with the provisions of the local law. A small administrative fee may be payable for the provision of information. If you would like a copy of the information which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at info@baobab.deals .
              </li>
              <li>
              We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in local law.
              </li>
            </ul>


            <h1>8. Complaints about privacy</h1>
            <ul>
              <li>
              If you have any complaints about our privacy practices, please feel free to send in details of your complaints to UNIT 1008 10/F PROSPERITY MILLENIA PLAZA 663 KINGS ROAD, QUARRY BAY, HONG KONG. We take complaints very seriously and will respond shortly after receiving written notice of your complaint.
              </li>
            </ul>


            <h1>9. Op out right</h1>
            <ul>
              <li>
              You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at info@baobab.deals.
              </li>
            </ul>

            <h1>10. Changes to Privacy Policy</h1>
            <ul>
              <li>
              Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy. Once changes have been affected, we will update the privacy policy as required according to adaptation. 
              </li>
            </ul>

            <h1>11. Software Application</h1>
            <ul>
                <li>When you use our Application. When you come to our application, we may retrieve certain information such as mobile unique device ID, the IP address of your mobile device, mobile operating system, the type of mobile internet browsers you use, and information about the way you use the Application. This information is used in an aggregated manner to analyze how people use our site, such that we can improve our service.</li>
                <li>Cookies. We may from time to time use cookies on our software application. Cookies are very small files which a website uses to identify you when you come back to the application and to store details about your use of the application. Cookies are not malicious programs that access or damage your computer, tablet or smartphone. Most devices automatically accept cookies, but you can choose to reject cookies by changing your devise settings. However, this may prevent you from taking full advantage of our application.</li>
                <li>Automatic collection. The software Application may retrieve certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.</li>
                <li>Third parties. Our software application may from time to time have links to other applications or websites not owned or controlled by us. These links are meant for your convenience only. Links to third party applications and websites do not constitute sponsorship or endorsement or approval of these third parties. Please be aware that BEHOBE TECH LIMITED is not responsible for the privacy practices of other such applications or websites. We encourage our users to be aware, when they leave our application or website, to read the privacy statements of each application or website that collects personal identifiable information.</li>
                <li>Geo-location. When you visit the mobile application, we may use GPS technology (or other similar technology) to determine your current location in order to determine the Approved by city you are located within and display a location map with relevant advertisements. We will not share your current location with other users or partners.</li>
            </ul>

            <h1>12. Contact us</h1>
            <p>If you have any questions about the use of your personal information, please send us an email to info@baobab.deals .  Unless otherwise stated, BEHOBE TECH LIMITED is the data controller for personal data we retrieve through the Services subject to this Privacy Policy. Our address UNIT 1008 10/F PROSPERITY MILLENIA PLAZA 663 KINGS ROAD, QUARRY BAY, HONG KONG</p>
            </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPageNoPadding>
  );
};
