import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import ReactModalAdapter from "../../helpers/ReactModalAdapter.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import axios from 'axios';


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100  relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`


const StyledModal = styled(ReactModalAdapter)`
  &.mainHeroModal__overlay {
    ${tw`fixed inset-0 z-50 `}
  }
  &.mainHeroModal__content {
    ${tw`lg:m-40 sm:m-16 max-w-screen-xl absolute inset-0 flex justify-center items-center rounded-lg bg-gray-200 outline-none bg-opacity-100  `}
  }
  .content {
    ${tw`w-full p-4 lg:p-16`}
  }
  h2 {
    ${tw`text-2xl sm:text-3xl `}
  }
  h3 {
    ${tw`text-lg sm:text-xl `}
  }
`;
const CloseModalButton = tw.button`absolute top-0 right-0 mt-8 mr-8 hocus:text-primary-500`;


function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default () => {

  const [email, setEmail] = React.useState("")
  const [phone, setPhone] = React.useState("")
  const [msg, setMsg] = React.useState("")

  const [isError, setIsError] = React.useState(false)

  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);


  React.useEffect(() => {
    console.log(email)
  }, [email])


  const onSubmit = () => {

    setIsError(false)

    if (!email || !msg || !validateEmail(email)){
      console.log("Please enter your email address and the message")
      setIsError(true)
      toggleModal()
      return
    }

    toggleModal()

    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute("6LcxEv8cAAAAAN7kgD9nIhszH3RK5jLZHk9flaWK", { action: "homepage" })
        .then(token => {
          const gatewayUrl = (window.location.href.includes('localhost') 
                            ? 'http://127.0.0.1:5000/contact_form/' : 'https://baobab.deals/contact_form/')
          axios({
              url: gatewayUrl,
              method: 'post',
              data: { token, email, phone, msg },
              headers: {
                'Content-Type': 'application/json',
              }
          })
        .catch((error) => {
            console.log(error)
        })
      })
    })
  }

  React.useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?render=6LcxEv8cAAAAAN7kgD9nIhszH3RK5jLZHk9flaWK"
    document.body.appendChild(script)
  }, [])



  return (
    <Container id="contact">
      
        <Content>
          <FormContainer>
            <div tw="mx-auto max-w-4xl">
              <h2>Reach out</h2>
                <TwoColumn>
                  <Column>
                    <InputContainer>
                      <Label htmlFor="name-input">Email</Label>
                      <Input id="name-input" type="text" name="name" value={email} onChange={(e) => setEmail(e.target.value) } placeholder="E.g. John.Doe@gmail.com" />
                    </InputContainer>
                    <InputContainer>
                      <Label htmlFor="email-input">Mobile</Label>
                      <Input id="phone-input" type="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value) } placeholder="034xxx" />
                    </InputContainer>
                  </Column>
                  <Column>
                    <InputContainer tw="flex-1">
                      <Label htmlFor="name-input">Message</Label>
                      <TextArea id="message-input" name="message" value={msg} onChange={(e) => {console.log(e.target.value); setMsg(e.target.value)} } placeholder="E.g. Please give us some details..."/>
                    </InputContainer>
                  </Column>
                </TwoColumn>

                <SubmitButton onClick={() => onSubmit()}>Submit</SubmitButton>
              
            </div>
            <SvgDotPattern1 />
          </FormContainer>
        </Content>
        <StyledModal
          closeTimeoutMS={300}
          className="mainHeroModal"
          isOpen={modalIsOpen}
          onRequestClose={toggleModal}
          shouldCloseOnOverlayClick={true}
        >
          <CloseModalButton onClick={toggleModal}>
            <CloseIcon tw="w-6 h-6" />
          </CloseModalButton>
          <div className="content">
            { !isError 
              ? (<><h2>Thanks for Reaching out!</h2><br />
                <h3>We will get in touch with You shortly.</h3></>)
              : (<><h2 style={{ color: '#a31208'}}>Error</h2>
                <h3 style={{ color: '#a31208'}}>Please give us your email</h3></>)
            }
          </div>
        </StyledModal>
    </Container>
  );
};

      // <div style={{ backgroundColor: 'yellow', position: 'absolute', zIndex: 30, width: "60%", height: "40%" }}></div>