import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase
} from "../headers/light.js";
import { Container as ContainerBase, ContentWithVerticalPadding, Content2Xl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/logo.png";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";

import Lottie from 'react-lottie';
import dataCenterLottie from '../../images/lottie/data-center';
import logoLottie from '../../images/lottie/logo';

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`


const logoAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: logoLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: dataCenterLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

export default ({
  heading = "Serverless First",
  description = "Our mission is to help deliver cutting-edge cloud solutions that are optimized to drive innovation and success. Our experts have the skills and experience to maximize control, minimize costs, and ensure critical data remains secure in the cloud. ",
  primaryButtonText = "Tell me more",
  primaryButtonUrl = "#contact",
  imageSrc = serverIllustrationImageSrc,
}) => {
  const logoLink = (
    <LogoLink href="/">
      <Lottie 
        options={logoAnimationOptions}
          height={40}
          width={40}
        />
      <small>Behobe Tech</small>
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <PrimaryLink href="#contact">Contact</PrimaryLink>
    </NavLinks>
  ];
  return (
    <PrimaryBackgroundContainer>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <PrimaryButton as="a" href={primaryButtonUrl}>{primaryButtonText}</PrimaryButton>
              </TextColumn>
              <IllustrationColumn>
              <Lottie 
                options={defaultAnimationOptions}
                  height={400}
                  width={400}
                />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
