import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import AnimationRevealPageNoPadding from "helpers/AnimationRevealPageNoPadding.js";

import { ReactComponent as SvgDotPatternIcon } from "./images/dot-pattern.svg";

import Header, { LogoLink, NavToggle } from "./components/headers/light.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import logo2 from "./images/baobab/logo2.png";


const IOS_LINK = 'itms-apps://apps.apple.com/app/id1600433802'
const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=deals.baobab'

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-45 translate-y-32 -z-10 opacity-50 text-primary-500 fill-current w-24 overflow-hidden `;


const StyledHeader = styled(Header)`
  ${tw`pt-0 pb-0 -mb-2 lg:mb-0 -ml-2 lg:ml-0 lg:pt-4 lg:pb-2 max-w-none w-full`}
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;


const HeroContainer = tw.div`z-10 relative px-6 sm:px-8 mx-auto h-full flex flex-col border-b-2`;

const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  let results = regex.exec(window.location.hash);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const timeLeftFromExpiry = (iso_datetime) => {

  let d = Date.parse(iso_datetime);
  let dd = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Ho_Chi_Minh' }))

  let sec = (d - dd) / 1000
  var h = Math.floor(sec / 3600);
  var m = Math.floor(sec % 3600 / 60);

  if (h < 0 || m < 0) {
    return false
  }

  return h + "h : " + m + "m"
}

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}


const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto lg:py-8 sm:py-0 grid grid-cols-1 items-center justify-items-center`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;


const Card = tw.div`h-full w-full flex! flex-col sm:border max-w-2xl sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);
const LogoContainer = tw.div`z-20 h-24 w-full bg-transparent justify-center grid invisible lg:visible`;
const LogoContainerInner = tw.div`relative h-20 w-20 bg-gray-100 rounded-4xl border`;


const TextInfo = tw.div`py-6 sm:px-10 sm:py-6 lg:ml-0 ml-0 text-center lg:text-left`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const Business = tw.h5`text-sm lg:text-lg text-gray-600`;

const PriceInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-0 mb-2 sm:mt-0 justify-center`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Price = tw.div`lg:ml-2 sm:ml-0 font-bold`;
const PriceOriginal = tw.div`ml-2 line-through text-gray-600`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto lg:text-lg rounded-none w-80 lg:w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 mb-6 lg:mb-0 text-center`;
export default () => {

  const [dealDetails, setDealDetails] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);
  const [btnText, setBtnText] = React.useState("CLAIM NOW");
  const [btnLink, setBtnLink] = React.useState(IOS_LINK);

  const dealId = getUrlParameter('id')

  React.useEffect(() => {
    if (!dealId) {
      return
    }

    const os = getMobileOperatingSystem()
    if (os == 'Android') {
      setBtnLink(ANDROID_LINK)
    }

    try {
      const path = 'deals/' + dealId + '.json'
      fetch(path)
        .then(response => response.json())
        .then(data => {

          setDealDetails({
            imageSrc: data.image_url,
            subtitle: data.business_name,
            title: data.title,
            description: data.description,
            url: 'google.com',
            locationText: 'locationText',
            pricingText: 'pricingText',
            price: data.price_text || data.price,
            discounted_price: data.discounted_price_text || data.discounted_price,
            discounted_perc: data.discounted_perc,
            business_name: data.business_name,
            seats: data.seats,
            expiry: data.expiry,
            business_address: data.business_address,
            business_logo: data.business_logo
          })
          setLoaded(true)
        });

    }
    catch (err) {
      console.log(err)
      setLoaded(true)
    }

  }, [dealId])

  const [timeLeft, setTimeLeft] = React.useState("")

  React.useEffect(() => {
    if (!dealDetails) {
      return
    }
    const timeLeft = timeLeftFromExpiry(dealDetails.expiry)
    if (!timeLeft) {
      setTimeLeft('Expired')
      return
    }

    setTimeLeft("Expires in " + timeLeft)
    const interval = setInterval(() => {
      const intervalTimeLeft = timeLeftFromExpiry(dealDetails.expiry)
      if (!intervalTimeLeft) {
        clearInterval(interval)
        setTimeLeft('Expired')
        return
      }
      setTimeLeft("Expires in " + intervalTimeLeft)
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [dealDetails]);

  React.useEffect(() => {
    if (!timeLeft) {
      setBtnText('CHECK OTHER DEALS')
    }
  }, [timeLeft]);





  const logoLink = (
    <LogoLink href="/" >
      <img src={logo2} alt="logo" style={{ height: "40px", width: "150px" }} />
    </LogoLink>
  );


  return (
    <AnimationRevealPageNoPadding disabled={false}>

      <Container>
        <HeroContainer>
          <StyledHeader links={[]} logoLink={logoLink} />
        </HeroContainer>
        <Content>
          <HeadingWithControl>
            <Heading></Heading>
          </HeadingWithControl>
          <Card key="deal">
            <CardImage imageSrc={dealDetails.imageSrc}>
              <LogoContainer>
                <LogoContainerInner style={{ top: '220px' }}>
                  <img src={dealDetails.business_logo} alt="logo" style={{ borderRadius: '45px' }} />
                </LogoContainerInner>
              </LogoContainer>
            </CardImage>
            <TextInfo>
              <TitleReviewContainer>
                <Title>{dealDetails.title}</Title>
                <PriceInfo >
                  <Price>{dealDetails.discounted_price}</Price>
                  <PriceOriginal>{dealDetails.price}</PriceOriginal>
                </PriceInfo>
              </TitleReviewContainer>
              <SecondaryInfoContainer>
                <Business>{dealDetails.business_name}, {dealDetails.business_address}</Business>
              </SecondaryInfoContainer>
              <SecondaryInfoContainer style={{ marginTop: 10, marginBottom: -10 }}>
                <Business>{timeLeft}</Business>
              </SecondaryInfoContainer>
              <Description>{dealDetails.description}</Description>

            </TextInfo>

            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <PrimaryButton as="a" target="_blank" style={{ backgroundColor: '#007141' }} href={btnLink}>
                {btnText}
              </PrimaryButton>
            </div>
          </Card>
        </Content>

      </Container>
      <SvgDotPattern1 />
      <Footer style={{ marginLeft: 0 }} />
    </AnimationRevealPageNoPadding>
  );
};

